import {
  DefaultButton,
  PrimaryButton,
  TextField,
  MessageBar,
  MessageBarType,
  ComboBox,
} from "@fluentui/react";
import * as Helpers from "../../helpers/helpers";
import CustomOverlay from "../customOverlay/customOverlay";
import * as React from "react";

/* global Office */

export default class FolderInformation extends React.Component {
  constructor(props) {
    super(props);

    const companyName = Office.context.document.settings.get("company-name");
    const companyId = Office.context.document.settings.get("company-id");
    const companyText = Office.context.document.settings.get("company-text");

    const folders = this.getDefaultFolderCollection(companyId, companyText);

    this.state = {
      companyName: companyName,
      companyId: companyId,
      folders: folders,
    };
  }

  render() {
    let folders = this.state.folders;
    if (this.state.folders.length === 0) {
      folders = [
        { key: 0, text: "Aucun dossier comptable", disabled: true }
      ];
    }

    const comboBoxStyles = {
      optionsContainerWrapper: {
        maxWidth: "350px",
        minWidth: "350px",
      },
    };

    return (
      <div>
        {this.state.errorMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {this.state.errorMessage}
            {this.state.documentationUrl && <div>Plus d'informations :<a href={this.state.documentationUrl}>bizpro.ch</a></div>}
          </MessageBar>
        )}
        {this.state.isSaved === true && (
          <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
            Les données ont été enregistrées.
          </MessageBar>
        )}
        {this.state.succeeded === true && (
          <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
            La liste des dossiers comptables a été obtenue.
          </MessageBar>
        )}

        <div className="main-container">
          <h2>Paramètres du dossier</h2>
          <TextField
            label="Nom de la société"
            required
            value={this.state.companyName}
            onChange={this.companyNameChange}
          />
          <PrimaryButton
            text="Obtenir la liste des dossiers"
            className="button"
            onClick={this.getFolders.bind(this)}
          />
          <ComboBox
            label="Dossier comptable"
            required
            options={folders}
            styles={comboBoxStyles}
            selectedKey={this.state.companyId}
            onChange={this.folderChange.bind(this)}
          />
          <PrimaryButton
            text="Enregistrer"
            className="button"
            onClick={this.save.bind(this)}
          />
          <DefaultButton
            text="Annuler"
            className="button"
            onClick={this.cancel.bind(this)}
          />
          {this.state.isGettingData && (
            <CustomOverlay loadingLabel="Obtention des données..." />
          )}
        </div>
      </div>
    );
  }

  companyNameChange = (event) => {
    this.setState({
      companyName: event.target.value,
      companyId: null,
      companyText: null,
      folders: [],
      isSaved: false,
      succeeded: false,
    });
  };

  folderChange = (_, selectedValue) => {
    let companyId = null;
    let companyText = null;
    
    if (!isNaN(Number(selectedValue.key))) {
      companyId = Number(selectedValue.key);
      companyText = selectedValue.text
    }

    this.setState({
      companyId: companyId,
      companyText: companyText,
      isSaved: false,
    });
  };

  getDefaultFolderCollection(companyId, companyText) {
    let folders = [];
    if (companyText) {
      folders = [
        { key: companyId, text: companyText }
      ]
    }
    return folders;
  }

  async getFolders() {
    this.setState({
      isGettingData: true,
      errorMessage: null,
      documentationUrl: null,
      isSaved: false,
      succeeded: false,
    });

    try
    {
      const username = Office.context.document.settings.get("username");
      const password = sessionStorage.getItem("password");
      const companyName = this.state.companyName;

      // Get folders
      const bizProApiFolders = new Helpers.BizProApi(
        username,
        password,
        companyName
      );
      const foldersRaw = await bizProApiFolders.getFoldersAsync();

      // Format folders
      var folders = foldersRaw.map(function(folder) {
        return { key: folder.dos_numero, text: folder.dos_numero.toString() + " - " + folder.dos_name };
      });

      this.setState({
        folders: folders,
        succeeded: true,
      });
    } catch (exception) {
      this.setState({
        errorMessage: exception.Message,
        documentationUrl: exception.DocumentationUrl,
      });
    } finally {
      this.setState({
        isGettingData: false,
      });
    }
  }

  save() {
    Office.context.document.settings.set("company-name", this.state.companyName);
    Office.context.document.settings.set("company-id", this.state.companyId);
    Office.context.document.settings.set("company-text", this.state.companyText);
    Office.context.document.settings.saveAsync();

    this.setState({
      errorMessage: null,
      documentationUrl: null,
      isSaved: true,
      succeeded: false,
    });
  }

  cancel() {
    const companyName = Office.context.document.settings.get("company-name");
    const companyId = Office.context.document.settings.get("company-id");
    const companyText = Office.context.document.settings.get("company-text");

    const folders = this.getDefaultFolderCollection(companyId, companyText);

    this.setState({
      companyName: companyName,
      companyId: companyId,
      companyText: companyText,
      folders: folders,
      errorMessage: null,
      documentationUrl: null,
      isSaved: false,
      succeeded: false,
    });
  }
}
