import * as React from "react";
import { IconButton } from "@fluentui/react";

export default class Header extends React.Component {
  render() {
    const iconProps = {
      iconName: "SignOut",
    };

    const { isLogoutVisible } = this.props;

    return (
      <div id="header" className="header">
        <div>
          <img src={require("./../../bizpro-logo.png")} alt="logo" />
        </div>
        {isLogoutVisible && (
          <div className="logout">
            <IconButton
              iconProps={iconProps}
              title="Se déconnecter"
              ariaLabel="Se déconnecter"
              onClick={this.logout.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }

  logout() {
    this.props?.onLogout();
  }
}
