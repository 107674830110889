import configuration from "./../config.json";
import BusinessException from "./../classes/BusinessException"
import * as CryptoJS from 'jsencrypt';
import { v4 as uuidv4 } from 'uuid';

/* global Office */
/* global Excel */

export function isNullOrEmpty(value) {
  return !(typeof value === "string" && value.length > 0);
}

export function encryptPassword(password) {
  if (!password || password.length === 0) {
    return "";
  }
  
  const winbizCloudPublicKey = "-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMLrpmZu3cBDlbw8dg71kuaGsq2rbpyS8KwazYZcC1imRWt7WguSpe3dcQKB49QHQOX4Va512C0XqrNtR3qte3hkju/A8wslpcvpX9sk0blDdsG4IwN8PDw9c0aZxaRGXDo09aILlhYqHkzBetWrlDbO3OCHhnF9XSAJTpnWzeWQIDAQAB-----END PUBLIC KEY-----";
  const bizProPublicKey = "-----BEGIN PUBLIC KEY-----MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgBXl8yGMmZ9mbNRDv5tbi0EU8RmSnEpbAeryOqC/fo81VF+2o/o32WhXO29Ajqxg4oZfa7mzJqcNuOb9cR+faW7VAvrV7/XI29FnB3a96364koB2xPWC7I+xIbEWgcpO5k35AOVTeUtMnNiHfhaB/USqJUurBZU2iLufBAGzyZiR/vWPh6OHphFeR2LzhcKQ+fSEBWoAmw7QogMB6JjGxBjle4uGJAbsyjdH1DzfK79XDP94WMw40OHpC1Inn+UrM3kKVaMqSwd+lUFSd9IBoTpQuHkEkIA1V4L6GH48e4+70pJLUOmfr3P1AZmOva9p4oi8lN3eU66M53Xhv8AOqDcLnC9ynoZESuRV0th9+VWKcUltKA27M99sLck4xIzrPwKoL8gY/mh+5fXXriJUAak+5Lj58wWIIbwRdkxQO1hhoQi0TSXq/qt4OQPVf+ToPhj2W4qTXyLOb+FvV4ybCbIxfOpei7lZ51bOXTkKDyxhXy/TTM4ynFRBn9btpmpyf4RkBiUe8NySPQsRMDVXOcN3dcg4bfajVKqcDyglZdukuBcLJU7v5GwXzolL85k31ASiWUvvUsaRUD7s5Xw39AesTrVgNLNlkvhUSuqSDJpRH20kFQS+07i2p4YPJRWmRlSwlrI07Q0gggDMvtXy3nzt2EqxLkz+piufWZlx/Z3cQIDAQAB-----END PUBLIC KEY-----";

  const encrypt = new CryptoJS.JSEncrypt();
  encrypt.setPublicKey(winbizCloudPublicKey);
  const winbizCloudEncryptedPassword = encrypt.encrypt(password).toString();

  const encryptBizPro = new CryptoJS.JSEncrypt();
  encryptBizPro.setPublicKey(bizProPublicKey);
  const encryptedPassword = encryptBizPro.encrypt(winbizCloudEncryptedPassword).toString();

  return encryptedPassword;
}

export function newGuid() {
  return uuidv4();
}

export function log(text, object) {
  const json = JSON.stringify(object);
  console.log(text + " (" + json + ")");
}

export function createRangeArrayFromObject(inputArray) {
  // If is an exception, create the array from it
  if (inputArray instanceof BusinessException) {
    inputArray = [ { error: inputArray.Message } ];
  }

  // Check the length
  if (inputArray.length === 0) {
    return null;
  }

  // Iterate all array items to get the longest
  let longestItem = 0;
  let longestItemPropertiesCount = 0;
  for (let itemIndex = 0; itemIndex < inputArray.length; itemIndex++) {
    let propertiesCount = Object.keys(inputArray[itemIndex]).length;
    if (propertiesCount > longestItemPropertiesCount) {
      longestItem = itemIndex;
      longestItemPropertiesCount = propertiesCount;
    }
  }

  // Create the range array (add 1 for headers)
  let rangeArray = [inputArray.length + 1];

  // Add headers to range array
  const objectProperties = Object.keys(inputArray[longestItem]);
  let headersArray = [objectProperties.length];
  for (let headersPropertyIndex = 0; headersPropertyIndex < objectProperties.length; headersPropertyIndex++) {
    const headersPropertyName = objectProperties[headersPropertyIndex];
    headersArray[headersPropertyIndex] = headersPropertyName;
  }
  rangeArray[0] = headersArray;

  // Iterate all items
  for (let itemIndex = 0; itemIndex < inputArray.length; itemIndex++) {
    var propertiesArray = [Object.keys(inputArray[itemIndex])];
    for (let propertyIndex = 0; propertyIndex < objectProperties.length; propertyIndex++) {
      const propertyName = objectProperties[propertyIndex];
      propertiesArray[propertyIndex] = inputArray[itemIndex][propertyName];
    }
    rangeArray[itemIndex + 1] = propertiesArray;
  }

  return rangeArray;
}

export async function createWorksheetAsync(worksheetName) {
  const worksheet = await Excel.run(async (context) => {
    const worksheet = context.workbook.worksheets.getItemOrNullObject(worksheetName);
    worksheet.load();
    await context.sync();

    if (worksheet.isNullObject === false) {
      worksheet.delete();
    }

    var addedWorksheet = context.workbook.worksheets.add(worksheetName);
    addedWorksheet.load();
    await context.sync();

    addedWorksheet.visibility = "Hidden";
    return addedWorksheet;
  });
  return worksheet;
}

export class BizProApi {
  headers;

  constructor(username, password, companyName, companyId = null, year = null) {
    log("START - helper.js - BizProApi - Constructor");

    const headers = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("company-name", companyName);
    headers.set("company-id", companyId?.toString() ?? "0");
    headers.set("year", year?.toString() ?? "0");
    headers.set("username", username);
    headers.set("password", password);
    headers.set("x-correlation-id", newGuid());
    headers.set("x-addin-office-version", Office.context.diagnostics.version);
    headers.set("x-addin-office-platform", Office.context.diagnostics.platform);
    headers.set("x-addin-office-host", Office.context.diagnostics.host);
    headers.set("x-addin-office-displayLanguage", Office.context.displayLanguage);
    headers.set("x-addin-office-contentLanguage", Office.context.contentLanguage);
    headers.set("x-addin-version", "1.5.2.0");
    this.headers = headers;

    log("END - helper.js - BizProApi - Constructor");
  }

  static async pingAsync() {
    log("START - helper.js - BizProApi - pingAsync");
    
    try {
      const url = configuration.API_URL + "/Ping";
      const init = {
        method: "GET"
      };

      await fetch(url, init);
    }
    catch (exception) {
      log("ERROR - helper.js - BizProApi - pingAsync", exception);
      throw exception;
    }
    finally {
      log("END - helper.js - BizProApi - pingAsync");
    }
  }

  async getChartOfAccountsAsync(forceUpdate, dateEnd, dateStart, noStartingBalance) {
    const parameters = [
      forceUpdate,
      dateEnd,
      dateStart,
      noStartingBalance
    ];
    log("START - helper.js - BizProApi - getChartOfAccountsAsync", parameters);

    try {
      const result = await this.callApiAsync("ChartOfAccounts", parameters);
      return result;
    }
    catch (exception) {
      log("ERROR - helper.js - BizProApi - getChartOfAccountsAsync", exception);
      throw exception;
    }
    finally {
      log("END - helper.js - BizProApi - getChartOfAccountsAsync");
    }
  }

  async getChartOfAccountsMultipeAsync(parameters) {
    log("START - helper.js - BizProApi - getChartOfAccountsMultipeAsync", parameters);

    const length = parameters.length;

    let methods = new Array(length);
    for (let i = 0; i < length; i++) {
      const method = {
        Method: "ChartOfAccounts",
        Parameters: [
          parameters[i].forceUpdate,
          parameters[i].dateEnd,
          parameters[i].dateStart,
          parameters[i].noStartingBalance
        ]
      }
      methods[i] = method;
    }

    try {
      const result = await this.callApiMultipleAsync(methods);
      return result;
    }
    catch (exception) {
      log("ERROR - helper.js - BizProApi - getChartOfAccountsMultipeAsync", exception);
      throw exception;
    }
    finally {
      log("END - helper.js - BizProApi - getChartOfAccountsMultipeAsync");
    }
  }

  async getFoldersAsync() {
    const parameters = [];
    log("START - helper.js - BizProApi - getFoldersAsync", parameters);
    
    try {
      const result = await this.callApiAsync("Folders", parameters);
      return result;
    }
    catch (exception) {
      log("ERROR - helper.js - BizProApi - getFoldersAsync", exception);
      throw exception;
    }
    finally {
      log("END - helper.js - BizProApi - getFoldersAsync");
    }
  }

  async testLoginAsync() {
    const parameters = [];
    log("START - helper.js - BizProApi - testLoginAsync", parameters);
    
    try {
      const result = await this.callApiAsync("TestLogin", parameters);
      return result;
    }
    catch (exception) {
      log("ERROR - helper.js - BizProApi - testLoginAsync", exception);
      throw exception;
    }
    finally {
      log("END - helper.js - BizProApi - testLoginAsync");
    }
  }

  async callApiAsync(method, parameters) {
    const input = {
      method: method,
      parameters: parameters
    };
    const url = configuration.API_URL + "/BizInfo";

    log("START - helper.js - BizProApi - callApiAsync", input);

    const init = {
      headers: this.headers,
      body: JSON.stringify(input),
      method: "POST"
    };

    try {
      const response = await fetch(url, init);
      if (!response.ok) {
        log("WARNING - helper.js - callApiAsync - !response.ok");

        const data = await response.json();
        const error = data.Result;

        const businessException = new BusinessException();
        businessException.Message = error.Message;
        businessException.DocumentationUrl = error.DocumentationUrl;
        throw businessException;
      }

      const data = await response.json();
      const success = data.Result;
      return success.Result;
    } catch (exception) {
      log("ERROR - helper.js - BizProApi - callApiAsync", exception);

      if (exception instanceof BusinessException) {
        throw exception;
      }

      const businessException = {
        documentationUrl: null
      };

      if (typeof exception === "string") {
        businessException.Message = exception;
      } else {
        businessException.Message = "Une erreur s'est produite lors de l'appel aux services de BizPro Business Solutions.";
      }
      throw businessException;
    }
  }

  async callApiMultipleAsync(input) {
    const url = configuration.API_URL + "/BizInfoMultiple";

    log("START - helper.js - BizProApi - callApiMultipleAsync", input);

    const init = {
      headers: this.headers,
      body: JSON.stringify(input),
      method: "POST"
    };

    try {
      const response = await fetch(url, init);

      const data = await response.json();
      return data.Result;
    } catch (exception) {
      log("ERROR - helper.js - BizProApi - callApiMultipleAsync", exception);

      if (exception instanceof BusinessException) {
        throw exception;
      }

      const businessException = {
        documentationUrl: null
      };

      if (typeof exception === "string") {
        businessException.Message = exception;
      } else {
        businessException.Message = "Une erreur s'est produite lors de l'appel aux services de BizPro Business Solutions.";
      }
      throw businessException;
    }
  }
}