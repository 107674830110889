import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/* global Office */

initializeIcons();

const title = "Winbiz Cloud pour Excel";
let isOfficeInitialized = false;

const render = (Component) => {
  ReactDOM.render(
    <React.StrictMode>
      <Component title={title} isOfficeInitialized={isOfficeInitialized} />
    </React.StrictMode>,
    document.getElementById('root')
  );
};

/* Render application after Office initializes */
(async () => { 
  await Office.onReady(); 
  isOfficeInitialized = true;
  render(App);
})(); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
