import * as React from "react";
import * as Helpers from "../../helpers/helpers";
import { PrimaryButton, Text, TextField } from "@fluentui/react";
import CustomOverlay from "../customOverlay/customOverlay";
import "./connection.css";

/* global Office */

export default class Connection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.username,
    };
  }

  render() {
    const { username } = this.props;

    return (
      <div className="connection-main">
        <div className="connection-title">
          <Text variant="xxLarge">Connexion à Winbiz Cloud</Text>
        </div>

        <br />

        <TextField
          label="Utilisateur (Winbiz Cloud)"
          required
          value={username}
          onChange={this.usernameChange.bind(this)}
        />
        <TextField
          label="Mot de passe (Winbiz Cloud)"
          required
          type="password"
          canRevealPassword
          onChange={this.passwordChange.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
        />
        <PrimaryButton
          text="Connexion"
          className="button"
          onClick={this.connection.bind(this)}
        />
        {this.state.isConnectionInProgress && (
          <CustomOverlay loadingLabel="Connexion en cours..." />
        )}
      </div>
    );
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.connection();
    }
  };

  usernameChange = (event) => {
    this.setState({
      username: event.target.value,
    });
  };

  passwordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  async connection() {
    // Set state (session)
    sessionStorage.removeItem("password");
    sessionStorage.setItem("isConnected", "false");

    if (Helpers.isNullOrEmpty(this.state.username)) {
      this.props.onConnectionFailed?.("L'utilisateur (Winbiz Cloud) est obligatoire.");
      return;
    }
    if (this.state.username.indexOf("wb-") !== 0) {
      this.props.onConnectionFailed?.("L'utilisateur (Winbiz Cloud) doit commencer par \"wb-\".");
      return;
    }
    if (Helpers.isNullOrEmpty(this.state.password)) {
      this.props.onConnectionFailed?.("Le mot de passe (Winbiz Cloud) est obligatoire.");
      return;
    }

    // Set state
    this.setState({
      isConnectionInProgress: true,
      errorMessage: null,
      documentationUrl: null,
    });

    // Encrypt the password
    const encryptedPassword = Helpers.encryptPassword(this.state.password);

    // Call the API of BizPro
    let result = false;
    try {
      result = await this.testLoginAsync(
        this.state.username,
        encryptedPassword
      );
    } catch (exception) {
      this.setState({
        errorMessage: exception.Message,
        documentationUrl: exception.DocumentationUrl,
      });
    }

    // Trigger events
    if (!this.state.errorMessage) {
      // Save the username to document
      Office.context.document.settings.set("username", this.state.username);
      Office.context.document.settings.saveAsync();

      // Set state (session)
      sessionStorage.setItem("password", encryptedPassword);
      sessionStorage.setItem("isConnected", "true");

      // Set state
      this.setState({
        errorMessage: null,
        documentationUrl: null,
      });
      
      this.props.onConnectionSucceeded?.(result, encryptedPassword);
    } else {
      this.props.onConnectionFailed?.(this.state.errorMessage, this.state.documentationUrl);
    }

    // Set state
    this.setState({
      isConnectionInProgress: false
    });
  }

  async testLoginAsync(username, password) {
    const bizProApi = new Helpers.BizProApi(username, password, "office");

    try {
      const result = await bizProApi.testLoginAsync();
      return result;
    } catch (exception) {
      Helpers.log("ERROR - connection.js - testLoginAsync", exception);
      throw exception;
    }
  }
}
