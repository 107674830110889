import * as React from "react";
import { Overlay, Spinner, SpinnerSize } from "@fluentui/react";
import "./customOverlay.css";

export default class CustomOverlay extends React.Component {
  render() {
    const { loadingLabel } = this.props;

    return (
      <Overlay className="overlay">
        <Spinner size={SpinnerSize.large} label={loadingLabel} />
      </Overlay>
    );
  }
}
