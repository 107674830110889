import {
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  Text,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import * as Helpers from "./helpers/helpers";
import "./App.css";
import Header from "./components/header/header";
import Connection from "./components/connection/connection";
import FolderInformation from "./components/folderInformation/folderInformation";
import ChartOfAccounts from "./components/chartOfAccounts/chartOfAccounts";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    let isConnected = false;
    const isConnectedItem = sessionStorage.getItem("isConnected");
    if (isConnectedItem && isConnectedItem === "true") {
      isConnected = true;
    }

    let isUnderstood = false;
    const isUnderstoodItem = sessionStorage.getItem("isUnderstood");
    if (isUnderstoodItem && isUnderstoodItem === "true") {
      isUnderstood = true;
    }

    this.state = {
      isUnderstood: isUnderstood,
      isConnected: isConnected,
    };
  }

  render() {
    if (!this.state.isConnected) {
      if (!this.state.isUnderstood) {
        this.pingAsync();
        return (
          <div id="main">
            <Header />

            <div id="message">
              {this.state.connectionErrorMessage && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                  {this.state.connectionErrorMessage}
                  {this.state.connectionDocumentationUrl && <div>Plus d'informations :<a href={this.state.connectionDocumentationUrl}>bizpro.ch</a></div>}
                </MessageBar>
              )}
            </div>
            <div id="body" className="body">
              <div className="app-title">
                <Text variant="xxLarge">Winbiz Cloud pour Excel</Text>

                <br />
                <br />

                <Text variant="large">
                  Ce complément permet de récupérer des données comptables de Winbiz Cloud vers Excel.
                </Text>

                <br />
                <br />

                <Text variant="mediumPlus">
                  Avant de pouvoir utiliser ce complément avec votre accès Winbiz Cloud :
                </Text>

                <ol style={{ padding: "0px" }} className="list">
                  <li class="css-99">
                    <b>Étape 1</b> : Acheter "Winbiz Cloud pour Excel" sur Winbiz AppStore via <a href="https://www.bizpro.ch/winbizappstore" target="_blank" rel="noopener noreferrer">https://www.bizpro.ch/help/winbiz-app</a>.
                  </li>
                </ol>

                <PrimaryButton onClick={this.understood.bind(this)}>
                  J'ai compris
                </PrimaryButton>

                <br />
                <br />
                <br />

                <Text variant="medium">
                  Toute la documentation au sujet de ce complément se trouve sur <a href="https://www.bizpro.ch/help/winbiz" target="_blank" rel="noopener noreferrer">https://www.bizpro.ch/help/winbiz</a>
                </Text>
              </div>
              <br />
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <Header />

            <div id="message">
              {this.state.connectionErrorMessage && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                  {this.state.connectionErrorMessage}
                  {this.state.connectionDocumentationUrl && <div>Plus d'informations :<a href={this.state.connectionDocumentationUrl}>bizpro.ch</a></div>}
                </MessageBar>
              )}
            </div>

            <div id="body" className="body">
              <div className="connection-container">
                <Connection
                  onConnectionSucceeded={this.connectionSucceeded.bind(this)}
                  onConnectionFailed={this.connectionFailed.bind(this)}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div id="main">
        <Header isLogoutVisible="true" onLogout={this.logout.bind(this)} />

        <div id="message">
          {this.state.warningMessage && (
            <MessageBar
              messageBarType={MessageBarType.severeWarning}
              isMultiline={false}
            >
              {this.state.warningMessage}
            </MessageBar>
          )}
        </div>

        <Pivot className="pivot">
          <PivotItem headerText="Paramètres du dossier">
            <FolderInformation />
          </PivotItem>
          <PivotItem headerText="Données comptables">
            <ChartOfAccounts />
          </PivotItem>
        </Pivot>
      </div>
    );
  }

  connectionSucceeded(customer) {
    let warningMessage = null;
    if (!Helpers.isNullOrEmpty(customer?.WarningMessage)) {
      warningMessage = customer.WarningMessage;
    }

    this.setState({
      isConnected: true,
      warningMessage: warningMessage,
      connectionErrorMessage: null,
      connectionDocumentationUrl: null,
    });
  }

  connectionFailed(message, documentationUrl) {
    sessionStorage.setItem("isConnected", "false");

    this.setState({
      connectionErrorMessage: message,
      connectionDocumentationUrl: documentationUrl,
    });
  }

  logout() {
    sessionStorage.removeItem("password");
    sessionStorage.setItem("isConnected", "false");

    this.setState({
      isConnected: false,
    });
  }

  understood() {
    this.setState({
      isUnderstood: true,
    });
  }

  async pingAsync() {
    try {
      Helpers.BizProApi.pingAsync();
    } catch (exception) {
      Helpers.log("ERROR - App.js - pingAsync", exception);
    }
  }
}
