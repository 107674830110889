import {
  PrimaryButton,
  TextField,
  MessageBar,
  MessageBarType,
  Checkbox,
  ComboBox
} from "@fluentui/react";
import * as React from "react";
import BusinessExceptionClass from "../../classes/BusinessException";
import * as Helpers from "../../helpers/helpers";
import CustomOverlay from "../customOverlay/customOverlay";

/* global Excel, Office */

export default class ChartOfAccounts extends React.Component {
  constructor(props) {
    super(props);

    const currentYear = Office.context.document.settings.get(
      "chartofaccounts-current-year"
    );
    const yearsCount = Office.context.document.settings.get(
      "chartofaccounts-years-count"
    );
    const currentYearByMonths = Office.context.document.settings.get(
      "chartofaccounts-current-year-by-months"
    );
    const currentYearByMonthsTypes = [
      { key: 0, text: "Évolution sur la période" },
      { key: 1, text: "Évolution mois par mois (mouvements)" },
    ];
    const currentYearByMonthsSelectedType = Office.context.document.settings.get(
      "chartofaccounts-current-year-by-months-selected-type"
    ) ?? 0;

    this.state = {
      currentYear: currentYear,
      yearsCount: yearsCount,
      currentYearByMonths: currentYearByMonths,
      currentYearByMonthsTypes: currentYearByMonthsTypes,
      currentYearByMonthsSelectedType: currentYearByMonthsSelectedType
    };
  }

  render() {
    let currentYearByMonthsTypes = this.state.currentYearByMonthsTypes;
    const checkBoxStyles = {
      root: {
        marginTop: "10px",
      },
    };
    const comboBoxStyles = {
      root: {
        display: this.state.currentYearByMonths ? '' : 'none',
      },
      label: {
        display: this.state.currentYearByMonths ? '' : 'none',
      },
      optionsContainerWrapper: {
        maxWidth: "350px",
        minWidth: "350px"
      },
    };

    return (
      <div>
        {this.state.errorMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {this.state.errorMessage}
            {this.state.documentationUrl && <div>Plus d'informations :<a href={this.state.documentationUrl}>bizpro.ch</a></div>}
          </MessageBar>
        )}
        {this.state.isGetted === true && (
          <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
            Les données comptables ont été obtenues.
          </MessageBar>
        )}
        <div className="main-container">
          <h2>Données comptables</h2>
          <TextField
            label="Année N"
            required
            value={this.state.currentYear?.toString()}
            onChange={this.currentYearChange}
          />
          <TextField
            label="Nombre d'années à récupérer"
            required
            value={this.state.yearsCount?.toString()}
            onChange={this.yearsCountChange}
          />
          <Checkbox
            label="Obtenir également l'année N par mois"
            checked={this.state.currentYearByMonths}
            onChange={this.currentYearByMonthsChange}
            styles={checkBoxStyles}
          />
          <ComboBox
            label="Type d'évolution des comptes"
            options={currentYearByMonthsTypes}
            styles={comboBoxStyles}
            selectedKey={this.state.currentYearByMonthsSelectedType}
            onChange={this.currentYearByMonthsTypeChange.bind(this)}
          />
          <PrimaryButton
            text="Obtenir les données"
            className="button"
            onClick={this.getData.bind(this)}
          />
          {this.state.isGettingData && (
            <CustomOverlay loadingLabel="Obtention des données..." />
          )}
        </div>
      </div>
    );
  }

  currentYearChange = (e) => {
    let currentYear = null;

    if (!isNaN(parseInt(e.target.value)) && !isNaN(Number(e.target.value))) {
      currentYear = Number(e.target.value);
    } else {
      currentYear = null;
    }

    this.setState({
      currentYear: currentYear,
    });
  };

  yearsCountChange = (e) => {
    let yearsCount = null;

    if (!isNaN(parseInt(e.target.value)) && !isNaN(Number(e.target.value))) {
      yearsCount = Number(e.target.value);
    } else {
      yearsCount = null;
    }

    this.setState({
      yearsCount: yearsCount,
    });
  };

  currentYearByMonthsChange = (e) => {
    this.setState({
      currentYearByMonths: e.target.checked,
    });
  }

  currentYearByMonthsTypeChange = (_, selectedValue) => {
    this.setState({
      currentYearByMonthsSelectedType: selectedValue.key
    });
  };

  async getData() {
    this.setState({
      isGettingData: true,
      errorMessage: null,
      documentationUrl: null,
      isGetted: false,
    });

    try {
      if (!this.state.currentYear) {
        const businessException = {
          Message: "L'année N est obligatoire pour obtenir les données."
        };
        throw businessException;
      }
      if (!this.state.yearsCount) {
        const businessException = {
          Message: "Le nombre d'année est obligatoire pour obtenir les données."
        };
        throw businessException;
      }
      if (this.state.yearsCount > 15) {
        const businessException = {
          Message: "Le nombre d'année maximal est : 15."
        };
        throw businessException;
      }

      Office.context.document.settings.set("chartofaccounts-current-year", this.state.currentYear);
      Office.context.document.settings.set("chartofaccounts-years-count", this.state.yearsCount);
      Office.context.document.settings.set("chartofaccounts-current-year-by-months", this.state.currentYearByMonths);
      Office.context.document.settings.set("chartofaccounts-current-year-by-months-selected-type", this.state.currentYearByMonthsSelectedType);
      Office.context.document.settings.saveAsync();
      
      const companyName = Office.context.document.settings.get("company-name");
      if (Helpers.isNullOrEmpty(companyName)) {
        const businessException = {
          Message: "Le nom de la société est obligatoire pour obtenir les données."
        };
        throw businessException;
      }
      const companyId = Office.context.document.settings.get("company-id");
      if (!companyId) {
        const businessException = {
          Message: "Le dossier comptable est obligatoire pour obtenir les données.",
        };
        throw businessException;
      }
      const username = Office.context.document.settings.get("username");
      const password = sessionStorage.getItem("password");

      await this.loadChartOfAccounts(
        username,
        password,
        companyName,
        companyId,
        this.state.currentYear,
        this.state.yearsCount,
        this.state.currentYearByMonthsSelectedType
      );
      this.setState({
        isGetted: true,
      });
    } catch (exception) {
      this.setState({
        errorMessage: exception.Message,
        documentationUrl: exception.DocumentationUrl,
      });
    } finally {
      this.setState({
        isGettingData: false,
      });
    }
  }

    async loadChartOfAccounts(
        username,
        password,
        companyName,
        companyId,
        currentYear,
        yearsCount,
        yearsSelectedType
    ) {
    // Delete all worksheets of chart of accounts
    await this.deleteChartOfAccountsWorksheetsAsync();

    // Get all folders (to get the dates of exercises)
    const bizProApiFolders = new Helpers.BizProApi(
      username,
      password,
      companyName
    );
    const folders = await bizProApiFolders.getFoldersAsync();

    // Get the desired folder
    const filteredFolders = folders.filter(function (folder) {
      return folder.dos_numero === companyId;
    });
    if (filteredFolders.length === 0) {
      const businessException = {
        Message: "Le dossier comptable n'existe pas."
      };
      throw businessException;
    }
    const folder = filteredFolders[0];

    // Iterate all years
    for (let yearIndex = 0; yearIndex < yearsCount; yearIndex++) {
      // Get the current year to treat
      const yearToTreat = currentYear - yearIndex;

      // Get the name of worksheet and create it
      let worksheetName = "CAO_N";
      if (yearToTreat !== currentYear) {
        worksheetName = "CAO_N-" + yearIndex.toString();
      }
      const addedWorksheet = await Helpers.createWorksheetAsync(worksheetName);

      // Get the desired fiscal year
      const filteredFiscalYears = folder.exercices.filter(function (year) {
        return year.exc_annee === yearToTreat;
      });
      let fiscalYear = null;
      if (filteredFiscalYears.length !== 0) {
        fiscalYear = filteredFiscalYears[0];
      }

      // Get the dates of the fiscal year
      let result = null;
      if (!fiscalYear) {
        result = [ { error: "Année comptable introuvable." } ];
      } else {
        const startDate = fiscalYear.exc_debut;
        const endDate = fiscalYear.exc_fin;

        // Get chart of accounts
        const bizProApiChartOfAccounts = new Helpers.BizProApi(
          username,
          password,
          companyName,
          companyId,
          yearToTreat
        );
        try {
          result = await bizProApiChartOfAccounts.getChartOfAccountsAsync(
            true,
            endDate,
            startDate,
            false
          );
        } catch (exception) {
          result = exception;
        }
      }

      // Write to worksheet
      const rangeArray = Helpers.createRangeArrayFromObject(result);
      await Excel.run(async (context) => {
        var worksheet = context.workbook.worksheets.getItem(
          addedWorksheet.name
        );
        var range = worksheet.getRangeByIndexes(
          0,
          0,
          rangeArray.length,
          rangeArray[0].length
        );
        range.values = rangeArray;
        await context.sync();
      });
    }

    // Must get the current year N by months?
    if (this.state.currentYearByMonths === true) {
      const filteredCurrentFiscalYear = folder.exercices.filter(function (year) {
        return year.exc_annee === currentYear;
      });

      let currentFiscalYear = null;
      if (filteredCurrentFiscalYear.length !== 0) {
        currentFiscalYear = filteredCurrentFiscalYear[0];
      }

      // Only if current year N exists
      if (currentFiscalYear) {
        const fiscalYearStartDateRaw = currentFiscalYear.exc_debut;
        const fiscalYearStartDate = new Date(fiscalYearStartDateRaw);
        const fiscalYearFirstMonth = new Date(fiscalYearStartDate.getFullYear(), fiscalYearStartDate.getMonth(), 1);

        const fiscalYearEndDateRaw = currentFiscalYear.exc_fin;
        const fiscalYearEndDate = new Date(fiscalYearEndDateRaw);
        const fiscalYearLastMonth = new Date(fiscalYearEndDate.getFullYear(), fiscalYearEndDate.getMonth(), 1);

        // Create all chart of accounts requests
        let fiscalYearCurrentMonth = fiscalYearFirstMonth;
        let parameters = [];
        while (true) {
          if (fiscalYearCurrentMonth > fiscalYearLastMonth){
            break;
          }

          // Get the date to use with the selected type
          let queryStartDate = new Date(Date.UTC(fiscalYearFirstMonth.getFullYear(), fiscalYearFirstMonth.getMonth(), 1)).toISOString().slice(0, 10);
          if (yearsSelectedType === 1) {
            queryStartDate = new Date(Date.UTC(fiscalYearCurrentMonth.getFullYear(), fiscalYearCurrentMonth.getMonth(), 1)).toISOString().slice(0, 10);
          }

          parameters.push({
            "forceUpdate": false,
            "dateEnd": new Date(Date.UTC(fiscalYearCurrentMonth.getFullYear(), fiscalYearCurrentMonth.getMonth() + 1, 0)).toISOString().slice(0, 10),
            "dateStart": queryStartDate,
            "noStartingBalance": false
          });

          fiscalYearCurrentMonth = new Date(fiscalYearCurrentMonth.getFullYear(), fiscalYearCurrentMonth.getMonth() + 1, 1);
        }
       
        // Get the charts of accounts (with multiple method)
        let isException = false;
        let result = null;
        const bizProApiChartOfAccounts = new Helpers.BizProApi(
          username,
          password,
          companyName,
          companyId,
          currentYear
        );
        try {
          result = await bizProApiChartOfAccounts.getChartOfAccountsMultipeAsync(parameters);
        } catch (exception) {
          isException = true;
          result = exception;
        }

        if (!isException) {
          for (let monthIndex = 0; monthIndex < result.length; monthIndex++) {
            // Get the name of worksheet and create it
            let worksheetName = "CAO_N_M" + (monthIndex + 1).toString();
            const addedWorksheet = await Helpers.createWorksheetAsync(worksheetName);

            // Write to worksheet
            let monthResult;
            if (result[monthIndex].IsError === true) {
              monthResult = new BusinessExceptionClass();
              monthResult.Message = result[monthIndex].Message;
            } else {
              monthResult = result[monthIndex].Result;
            }
            const rangeArray = Helpers.createRangeArrayFromObject(monthResult);
            await Excel.run(async (context) => {
              var worksheet = context.workbook.worksheets.getItem(
                addedWorksheet.name
              );
              var range = worksheet.getRangeByIndexes(
                0,
                0,
                rangeArray.length,
                rangeArray[0].length
              );
              range.values = rangeArray;
              await context.sync();
            });
          }
        }
      }
    }

    // Recalculate all worksheets
    await Excel.run(async (context) => {
      const worksheets = context.workbook.worksheets;
      worksheets.load();
      await context.sync();

      worksheets.items.forEach(async function (worksheet) {
        worksheet.calculate(true);
      });
    });
  }

  async deleteChartOfAccountsWorksheetsAsync() {
    await Excel.run(async (context) => {
      // Load the worksheets
      context.workbook.worksheets.load();
      await context.sync();

      // Iterate on all worksheets
      const worksheetsCount = context.workbook.worksheets.items.length;
      for (
        let worksheetIndex = 0;
        worksheetIndex < worksheetsCount;
        worksheetIndex++
      ) {
        const currentWorksheet =
          context.workbook.worksheets.items[worksheetIndex];
        if (currentWorksheet.name.indexOf("CAO_") > -1) {
          currentWorksheet.delete();
        }
      }

      await context.sync();
    });
  }
}
